@use '@angular/material' as mat;
@import "@angular/material/theming";
@import 'theme-settings';
@include mat.core();

@include mat.all-component-themes($angular-default-theme);
@include mat.typography-hierarchy($typography);

.mat-app-background {
  background: white;
  min-height: 100vh;
}
